<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M3.741 1.40798L22.203 11.562C22.2814 11.6051 22.3468 11.6686 22.3923 11.7456C22.4378 11.8226 22.4618 11.9105 22.4618 12C22.4618 12.0895 22.4378 12.1773 22.3923 12.2544C22.3468 12.3314 22.2814 12.3948 22.203 12.438L3.741 22.592C3.66487 22.6339 3.57915 22.6552 3.49227 22.6538C3.4054 22.6525 3.32037 22.6285 3.24558 22.5843C3.17078 22.5401 3.1088 22.4772 3.06573 22.4017C3.02267 22.3263 3.00001 22.2409 3 22.154V1.84598C3.00001 1.7591 3.02267 1.67372 3.06573 1.59825C3.1088 1.52279 3.17078 1.45985 3.24558 1.41564C3.32037 1.37143 3.4054 1.34747 3.49227 1.34613C3.57915 1.34479 3.66487 1.36611 3.741 1.40798ZM5 13V19.617L18.85 12L5 4.38298V11H10V13H5Z"
    />
  </svg>
</template>

<script>
export default {
  name: "TriangleIcon",
};
</script>
